import React from "react";

function QHLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      version="1.1"
      viewBox="0 0 12.7 12.7"
      xmlSpace="preserve"
      fill="none"
    >
      <path
        fill = "currentColor"
        strokeWidth="0.111"
        d="M3.742.384v6.84h.003v.873l1.187.006v-.88h2.901l.014.538c.012.448.026.573.08.744.165.518.49.94.943 1.229a.691.691 0 00.159.083c.017 0 .02-1.663.01-4.4L9.018.702V.384h-.453a6.97 6.97 0 00-.59.02L7.84.421v5.622H4.958l-.02-.774c-.01-.425-.015-.873-.01-.995.005-.121.005-1.046 0-2.056L4.923.384h-.59zm3.39.648l-.744.008-.744.008-.008.566-.008.566.185.01c.362.02.66.021.988.003l.331-.02v-.57zM3.094 2.237l-.096.067a5.013 5.013 0 00-1.75 2.861c-.076.338-.082.411-.083 1.056-.002.558.009.75.053.987.13.696.5 1.59.87 2.108.224.313.64.777.907 1.01.246.216.767.563 1.086.725.495.251 1.21.461 1.714.503.16.013.33.025.38.026.144.004.723-.045.741-.063-.048-.617-1.156-1.082-1.633-1.275-.83-.296-1.306-.588-1.815-1.114C2.77 8.41 2.39 7.547 2.312 6.501c-.036-.488.068-1.234.246-1.754.103-.302.169-.437.377-.77l.159-.253V2.98zm6.604.122c-.02 0-.03.282-.03.859v.858l.099.136c.199.273.363.675.479 1.169.077.328.078 1.267.002 1.646-.088.436-.234.865-.444 1.176-.088.13-.116.2-.116.548 0 .196.023.796-.001.796l.005.57c.047 0 .495-.527.657-.727a4.79 4.79 0 00.892-1.627c.152-.475.198-.775.216-1.41.018-.617-.009-.911-.127-1.385-.235-.939-.668-1.706-1.32-2.336-.154-.15-.295-.273-.312-.273zM5.231 8.354c-.245-.002-.482.018-.619.06-.21.065-.634.273-.634.31 0 .05.34.325.553.447.117.067.422.193.677.281.85.291 1.095.442 1.803 1.106.249.233.567.527.707.654.664.602 1.231.933 1.817 1.064.3.066.824.047 1.075-.04a1.37 1.37 0 00.83-.795c.083-.197.094-.257.095-.504.002-.293-.043-.535-.128-.705l-.051-.101-.038.116a1.37 1.37 0 01-.355.577c-.267.261-.584.352-1.019.292-.515-.07-1.04-.421-1.942-1.299-.865-.84-1.411-1.2-2.108-1.39a2.885 2.885 0 00-.663-.073z"
        display="inline"
      ></path>
    </svg>
  );
}

export default QHLogo;